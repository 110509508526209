import withCheckRole from 'HOCs/withCheckRole';
import BaseUrl from 'consts/baseUrl';
import { PERMISSION_ENUM } from 'consts/index';
import { Route } from 'interfaces/common';
import { lazy } from 'react';

// Bash importHere
const DefaultLayout = lazy(() => import('layouts/DefaultLayout'));
const Homepage = lazy(() => import('pages/NewDesigns/Homepage'));
const Users = lazy(() => import('pages/Users'));
// const Settings = lazy(() => import('pages/Settings'));

// const Laucher = lazy(() => import('pages/Launcher'));
// const AppManagement = lazy(() => import('pages/Apps'));
const DetailApp = lazy(() => import('pages/NewDesigns/Apps/PageChild/Marketplace/DetailApp'));
const DetailAppReport = lazy(() => import('pages/NewDesigns/Apps/PageChild/Report/DetailApp'));
const Launcher = lazy(() => import('pages/NewDesigns/Launcher'));

const AppsLayout = lazy(() => import('layouts/AppsLayout'));

//! Marketplace
const Marketplace = lazy(() => import('pages/NewDesigns/Apps/PageChild/Marketplace'));
const Report = lazy(() => import('pages/NewDesigns/Apps/PageChild/Report'));
const Products = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Marketplace/PageChild/Products')
);
const ProductsReport = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Marketplace/PageChild/Products')
);
const Quote = lazy(() => import('pages/NewDesigns/Apps/PageChild/Marketplace/PageChild/Quote'));
const InfoApp = lazy(() => import('pages/NewDesigns/Apps/PageChild/Marketplace/PageChild/InfoApp'));
const InfoAppReport = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Report/PageChild/InfoApp')
);

//! Develop
const DevelopPage = lazy(() => import('pages/NewDesigns/Apps/PageChild/Develop'));
const UploadApps = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Develop/PageChild/UploadApp')
);
const UploadReport = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Develop/PageChild/UploadReport')
);
const ManageYourApps = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Develop/PageChild/ManageYourApps')
);
const ManageYourReport = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/Develop/PageChild/ManageYourReport')
);

//! My Apps
const MyApps = lazy(() => import('pages/NewDesigns/Apps/PageChild/MyApps'));
const MyAppDetail = lazy(() => import('pages/NewDesigns/Apps/PageChild/MyApps/MyAppDetail'));

const MyReport = lazy(() => import('pages/NewDesigns/Apps/PageChild/MyReports'));
const MyReportDetail = lazy(
  () => import('pages/NewDesigns/Apps/PageChild/MyReports/MyReportDetail')
);

//! Settings
const Settings = lazy(() => import('pages/NewDesigns/Apps/PageChild/Settings'));

//! Notification
const NotificationScreen = lazy(() => import('pages/NewDesigns/NotificationScreen'));

//! News
const NewsScreen = lazy(() => import('pages/NewDesigns/News'));

//! Apps Management
const AppsManagement = lazy(() => import('pages/NewDesigns/AppsManagement'));
const ReportsManagement = lazy(() => import('pages/NewDesigns/ReportsManagement'));

//! Category Management
const CategoryManagement = lazy(() => import('pages/NewDesigns/CategoryManagement'));
//! Event Management Management
const EventManagement = lazy(() => import('pages/NewDesigns/EventManagement'));
const AccountSetting = lazy(() => import('pages/AccountSetting'));

const CompanyManagement = lazy(() => import('pages/NewDesigns/Company'));
const AddCompany = lazy(() => import('pages/NewDesigns/Company/Components/AddEditCompany'));
const Help = lazy(() => import('pages/NewDesigns/Help'));
const AddHelp = lazy(() => import('pages/NewDesigns/Help/Components/AddEditHelp'));
const DescriptionHelp = lazy(() => import('pages/NewDesigns/Help/Components/DescriptionHelp'));

//! Recent Activity
const RecentActivity = lazy(() => import('pages/NewDesigns/RecentActivity'));

//! Notification Management
const NotificationManagement = lazy(() => import('pages/NewDesigns/NotificationManagement'));
const ApiDocs = lazy(() => import('pages/NewDesigns/ApiDocs'));
const ApiDocsDetail = lazy(() => import('pages/NewDesigns/ApiDocs/Components/DetailApiDoc'));

const routes: Route[] = [
  {
    name: 'Home Layout',
    path: '/',
    layout: DefaultLayout,
    routeChild: [
      // Bash appendHere
      {
        name: 'Homepage',
        path: BaseUrl.Homepage,
        component: withCheckRole(Homepage, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      // {
      //   name: 'App Management',
      //   path: BaseUrl.AppManagement,
      //   component: withCheckRole(AppManagement, [
      //     PERMISSION_ENUM.ADMIN,
      //     PERMISSION_ENUM.APP_MANAGER,
      //     PERMISSION_ENUM.USER,
      //   ]),
      //   isPrivateRoute: true,
      // },
      // {
      //   name: 'App Launcher',
      //   path: BaseUrl.AppLauncher,
      //   component: withCheckRole(Laucher, [
      //     PERMISSION_ENUM.ADMIN,
      //     PERMISSION_ENUM.APP_MANAGER,
      //     PERMISSION_ENUM.USER,
      //   ]),
      //   isPrivateRoute: true,
      // },
      // {
      //   name: 'Detail App',
      //   path: BaseUrl.AppLauncherDetail,
      //   component: withCheckRole(DetailApp, [
      //     PERMISSION_ENUM.ADMIN,
      //     PERMISSION_ENUM.APP_MANAGER,
      //     PERMISSION_ENUM.USER,
      //   ]),
      //   isPrivateRoute: true,
      // },
      {
        name: 'Users',
        path: BaseUrl.Users,
        component: withCheckRole(Users, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Settings',
        path: BaseUrl.Settings,
        component: withCheckRole(Settings, [PERMISSION_ENUM.PUBLIC]),
        isPrivateRoute: true,
      },
      {
        name: 'Notification',
        path: BaseUrl.Notification.Index,
        component: NotificationScreen,
      },
      {
        name: 'Launcher',
        path: BaseUrl.Launcher.App,
        component: Launcher,
      },
    ],
  },
  {
    name: 'Apps Layout',
    path: BaseUrl.Apps,
    layout: AppsLayout,
    routeChild: [
      {
        name: 'Marketplace',
        path: BaseUrl.Marketplace.Index,
        component: withCheckRole(Marketplace, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Report',
        path: BaseUrl.ReportApp.Index,
        component: withCheckRole(Report, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Products',
        path: BaseUrl.Marketplace.Products,
        component: withCheckRole(Products, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Products Report',
        path: BaseUrl.ReportApp.Products,
        component: withCheckRole(ProductsReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Quote',
        path: BaseUrl.Marketplace.Quote,
        component: withCheckRole(Quote, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Info App',
        path: BaseUrl.Marketplace.Info,
        component: withCheckRole(InfoApp, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Info App',
        path: BaseUrl.ReportApp.Info,
        component: withCheckRole(InfoAppReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },

      {
        name: 'MyApps',
        path: BaseUrl.MyApps.Index,
        component: withCheckRole(MyApps, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'MyApp Detail',
        path: BaseUrl.MyApps.Detail,
        component: withCheckRole(MyAppDetail, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },

      {
        name: 'MyReports',
        path: BaseUrl.MyReport.Index,
        component: withCheckRole(MyReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'MyReports Detail',
        path: BaseUrl.MyReport.Detail,
        component: withCheckRole(MyReportDetail, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },

      {
        name: 'Settings',
        path: BaseUrl.Setting.Index,
        component: withCheckRole(Settings, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },

      {
        name: 'Develop',
        path: BaseUrl.Develop.Index,
        component: withCheckRole(DevelopPage, [PERMISSION_ENUM.ADMIN, PERMISSION_ENUM.APP_MANAGER]),
      },
      {
        name: 'Upload apps',
        path: BaseUrl.Develop.UploadApps,
        component: withCheckRole(UploadApps, [PERMISSION_ENUM.ADMIN, PERMISSION_ENUM.APP_MANAGER]),
      },
      {
        name: 'Upload report',
        path: BaseUrl.Develop.UploadReport,
        component: withCheckRole(UploadReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
        ]),
      },
      {
        name: 'Edit report',
        path: BaseUrl.Develop.UploadReport,
        component: withCheckRole(UploadReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
        ]),
      },
      {
        name: 'Manage your apps',
        path: BaseUrl.Develop.ManageYourApps,
        component: withCheckRole(ManageYourApps, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
        ]),
      },
      {
        name: 'Manage your report',
        path: BaseUrl.Develop.ManageYourReport,
        component: withCheckRole(ManageYourReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
        ]),
      },
      {
        name: 'News screen',
        path: BaseUrl.News.Index,
        component: NewsScreen,
      },
      {
        name: 'Apps Management',
        path: BaseUrl.AppsManagement,
        component: withCheckRole(AppsManagement, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Report Management',
        path: BaseUrl.ReportManagement,
        component: withCheckRole(ReportsManagement, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Category Management',
        path: BaseUrl.CategoryManagement,
        component: withCheckRole(CategoryManagement, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Detail App',
        path: BaseUrl.ReportApp.AppReport,
        component: withCheckRole(DetailAppReport, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Detail App',
        path: BaseUrl.Marketplace.AppMarketPlace,
        component: withCheckRole(DetailApp, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Detail App',
        path: BaseUrl.EvenManagement.Index,
        component: withCheckRole(EventManagement, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Account Setting',
        path: BaseUrl.AccountSetting,
        component: withCheckRole(AccountSetting, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
          PERMISSION_ENUM.USER,
        ]),
        isPrivateRoute: true,
      },
      {
        name: 'Company Management',
        path: BaseUrl.Company.Index,
        component: withCheckRole(CompanyManagement, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Add Company',
        path: BaseUrl.Company.AddCompany,
        component: withCheckRole(AddCompany, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Edit Company',
        path: BaseUrl.Company.Edit,
        component: withCheckRole(AddCompany, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Recent Activity',
        path: BaseUrl.RecentActivity.Index,
        component: withCheckRole(RecentActivity, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Help Management',
        path: BaseUrl.Help.Index,
        component: withCheckRole(Help, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Add Help',
        path: BaseUrl.Help.AddHelp,
        component: withCheckRole(AddHelp, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Edit Company',
        path: BaseUrl.Help.Edit,
        component: withCheckRole(AddHelp, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Description Static',
        path: BaseUrl.Help.DescriptionHelp,
        component: withCheckRole(DescriptionHelp, [PERMISSION_ENUM.ADMIN]),
        isPrivateRoute: true,
      },
      {
        name: 'Notification Management',
        path: BaseUrl.NotificationManagement.Index,
        component: withCheckRole(NotificationManagement, [PERMISSION_ENUM.ADMIN]),
      },
      {
        name: 'Api Docs',
        path: BaseUrl.ApiDocs.Index,
        component: withCheckRole(ApiDocs, [PERMISSION_ENUM.ADMIN, PERMISSION_ENUM.APP_MANAGER]),
        isPrivateRoute: true,
      },
      {
        name: 'Api Docs Detail',
        path: BaseUrl.ApiDocs.Detail,
        component: withCheckRole(ApiDocsDetail, [
          PERMISSION_ENUM.ADMIN,
          PERMISSION_ENUM.APP_MANAGER,
        ]),
        isPrivateRoute: true,
      },
    ],
  },
];

export default routes;
