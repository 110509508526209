import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import BaseUrl from 'consts/baseUrl';
import { LOGOUT_REDIRECT_URI } from 'consts/configAWS';
import { deleteToken } from 'firebase/messaging';
import { IUser } from 'providers/AuthenticationProvider';
import { messaging } from '../firebase';
import AuthService from './authService';

export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';

class Services {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios;
    this.axios.defaults.withCredentials = false;
    //! Interceptor request
    this.axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    //! Interceptor response
    this.axios.interceptors.response.use(
      function (config) {
        return config;
      },
      async (error) => {
        if (error.response.data?.statusCode === 401) {
          await deleteToken(messaging);
          window.location.href = `${BaseUrl.Login}`;

          this.clearAuthStorage();
          window.location.href = `${BaseUrl.Login}`;
          window.sessionStorage.clear();
        }
        return Promise.reject(error);
      }
    );
  }

  setupInterceptors(authService: AuthService) {
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const { status } = error?.response || {};
        if (status === 401) {
          authService.removeUser();
          this.clearAuthStorage();

          window.location.href = LOGOUT_REDIRECT_URI;
          window.sessionStorage.clear();
        }

        console.log('123123', error);
        return Promise.reject(error);
      }
    );
  }

  attachTokenToHeader() {
    const token = localStorage.getItem(TOKEN_KEY);
    this.axios.interceptors.request.use(
      function (config) {
        if (config.headers) {
          // Do something before request is sent
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.axios.get(url, config);
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.axios.post(url, data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.axios.delete(url, config);
  }

  put(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.put(url, data, config);
  }

  patch(url: string, data: any, config?: AxiosRequestConfig) {
    return this.axios.patch(url, data, config);
  }

  saveTokenStorage(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  saveUserStorage(user: IUser) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getTokenStorage() {
    const token = localStorage.getItem(TOKEN_KEY);
    return token || '';
  }

  getUserStorage() {
    const user = localStorage.getItem(USER_KEY);
    if (user && user !== 'null') {
      return JSON.parse(user);
    }

    return null;
  }

  clearAuthStorage() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  }
}

export default new Services();
