import Button from './Button';
import Typography from './Typography';
import Loading from './Loading';
import Box from './Box';
import Table from './Table';
import Avatar from './Avatar';
import Tooltip from './Tooltip';
import TabPanel from './TabPanel';
import Chip from './Chip';
import Tabs from './Tabs';
import Badge from './Badge';

export default {
  Badge,
  Tabs,
  Chip,
  TabPanel,
  Avatar,
  Table,
  Button,
  Typography,
  Loading,
  Tooltip,
  Box,
};
