export enum AccessAppType {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  ACCESS = 'ACCESS',
  MANAGER = 'MANAGER',
}
export enum NotificationType {
  DEFAULT = 'DEFAULT',
}

export enum FORMAT_DATE {
  DATE_TIME = 'YYYY-MM-DD HH:mm:ss',
  DATE = 'YYYY-MM-DD',
  TIME = 'HH:mm:ss',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum Platform {
  WEB = 'WEB',
  APP = 'APP',
}

export enum LoginMethod {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum SocialConnectionType {
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
}

export enum UserRole {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Language {
  VI = 'VI',
  EN = 'EN',
}

export enum AppType {
  WEB_HOOK = 'WEB_HOOK',
  REPORT = 'REPORT',
}

export enum AppStatus {
  PENDING = 'PENDING',
  REJECT = 'REJECT',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  // BANNED = 'BANNED',
  // EXPRIED = 'EXPRIED',
}

export enum UserAppStatus {
  PENDING = 'PENDING',
  ACCESS = 'ACCESS',
  REQUEST = 'REQUEST',
}

export enum ServiceType {
  WEB_HOOK = 'WEB_HOOK',
}

export enum ServiceStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  EXPRIED = 'EXPRIED',
}

export enum NewsType {
  NEWS = 'NEWS',
  DIRECT = 'DIRECT',
  ACTIVITY = 'ACTIVITY',
}

export enum ReportType {
  LINK = 'LINK',
}

export enum CategoryType {
  DEFAULT = 'DEFAULT',
  REPORT = 'REPORT',
}

export enum StaticPageType {
  HELP = 'HELP',
}

export enum NotiDataType {
  DETAIL_APPLICATION = 'DETAIL_APPLICATION',
}
