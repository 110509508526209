import DoDisturbOffOutlinedIcon from '@mui/icons-material/DoDisturbOffOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import KeyIcon from '@mui/icons-material/Key';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import CloudIcon from '@mui/icons-material/Cloud';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DownloadingIcon from '@mui/icons-material/Downloading';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import * as IoIcons from 'react-icons/io5';
import { SiWindows11 } from 'react-icons/si';
import {
  IoEyeOffOutline,
  IoEyeOutline,
  IoSparklesOutline,
  IoBagHandleOutline,
  IoSearchOutline,
} from 'react-icons/io5';
import { PiMedalLight } from 'react-icons/pi';
import LaunchIcon from '@mui/icons-material/Launch';
import { MdOutlineLaunch } from 'react-icons/md';
import { FaAws } from 'react-icons/fa6';
import { PiNewspaperLight } from 'react-icons/pi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { RiEditLine } from 'react-icons/ri';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const IconApplication1 = (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.912 22.1307L36.912 9.68533C34.024 7.43733 29.9787 7.43733 27.088 9.68533L11.088 22.1307C9.13867 23.6453 8 25.976 8 28.4453V48C8 52.4187 11.5813 56 16 56H48C52.4187 56 56 52.4187 56 48V28.4453C56 25.976 54.8613 23.6453 52.912 22.1307Z'
      stroke='white'
      strokeWidth='2.5'
    />
    <path
      d='M42.6667 37.968C36.7734 43.8614 27.2214 43.8614 21.3334 37.968'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconApplication2 = (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'>
    <path
      d='M56 56L57.5414 52.9174C58.2816 51.4367 58.6669 49.8039 58.6667 48.1485V30.6667C58.6667 28.4575 56.8758 26.6667 54.6667 26.6667C52.4575 26.6667 50.6667 28.4575 50.6667 30.6667V41.3334'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M37.3333 56V48.2311C37.3333 47.0771 37.7076 45.9543 38.4 45.0311L42.8745 39.065C43.6274 38.0611 44.779 37.4333 46.0308 37.3443C47.2825 37.2554 48.5113 37.714 49.3987 38.6014C50.922 40.1247 51.0934 42.5363 49.8008 44.2598L46.9956 48'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.99998 56L6.45865 52.9174C5.71836 51.4367 5.33307 49.8039 5.33331 48.1485V30.6667C5.33331 28.4575 7.12417 26.6667 9.33331 26.6667C11.5425 26.6667 13.3333 28.4575 13.3333 30.6667V41.3334'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26.6667 56V48.2311C26.6667 47.0771 26.2924 45.9543 25.6 45.0311L21.1255 39.065C20.3726 38.0611 19.221 37.4333 17.9692 37.3443C16.7175 37.2554 15.4887 37.714 14.6013 38.6014C13.078 40.1247 12.9066 42.5363 14.1992 44.2598L17.0044 48'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.5879 31.8602C24.4315 30.6289 20 25.2234 20 18.9451V14.6667C20 10.9848 22.9848 8 26.6667 8H37.3333C41.0152 8 44 10.9848 44 14.6667V18.945C44 25.2232 39.5687 30.6287 33.4124 31.8601C32.4802 32.0466 31.5201 32.0466 30.5879 31.8602Z'
      stroke='white'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default {
  InboxIcon,
  MailIcon,
  LeftIcon: ChevronLeftIcon,
  RightIcon: ChevronRightIcon,
  MenuIcon,
  LogoutIcon,
  CloudIcon,
  AddIcon,
  EditIcon,
  DownloadingIcon,
  UploadingIcon: CloudUploadOutlinedIcon,
  UninstallIcon: RestoreFromTrashIcon,
  NoteAddIcon: NoteAddOutlinedIcon,
  BookIcon: MenuBookIcon,
  Users: GroupIcon,
  Search: IoSearchOutline,
  Reset: RestartAltIcon,
  SettingsIcon: IoIcons.IoSettingsOutline,
  SaveIcon,
  AssignIcon: PersonAddOutlinedIcon,
  UnAssignIcon: PersonOffIcon,
  CheckIcon,
  CloseIcon,
  SendIcon: SendOutlinedIcon,
  AdminPanelSettingsIcon,
  SettingsAccessibilityIcon,
  KeyIcon,
  AppsIcon: IoIcons.IoAppsOutline,
  AssignmentChecked: AssignmentTurnedInOutlinedIcon,
  CheckedAndAdd: AddTaskIcon,
  DenyAccess: DoDisturbOffOutlinedIcon,
  RequestAccess: FileOpenOutlinedIcon,
  HelpIcon: IoIcons.IoHelpCircleOutline,
  NotificationIcon: IoIcons.IoNotificationsOutline,
  RightArrowIcon: IoIcons.IoArrowForwardSharp,
  LeftArrowIcon: IoIcons.IoArrowBackSharp,
  DownArrowIcon: IoIcons.IoArrowDownSharp,
  AtIcon: IoIcons.IoAtOutline,
  LockIcon: IoIcons.IoLockClosedOutline,
  LogoWindow11Icon: SiWindows11,
  EyeOpenIcon: IoEyeOutline,
  EyeCloseIcon: IoEyeOffOutline,
  SparkesIcon: IoSparklesOutline,
  BagHandleIcon: IoBagHandleOutline,
  MedalIcon: PiMedalLight,
  HintIcon: IoIcons.IoInformationCircleOutline,
  HomeIcon: IoIcons.IoHomeOutline,
  IoHeartOutline: IoIcons.IoHeartOutline,
  IoSearchOutline: IoIcons.IoSearchOutline,
  IoListOutline: IoIcons.IoListOutline,
  IoHomeOutline: IoIcons.IoHomeOutline,
  IoEllipsisVerticalSharp: IoIcons.IoEllipsisVerticalSharp,
  IoArrowForwardOutline: IoIcons.IoArrowForwardOutline,
  IoClose: IoIcons.IoClose,
  LaunchIcon,
  MdOutlineLaunch,
  FaAws,
  PiNewspaperLight,
  RiDeleteBin7Line,
  RiEditLine,
  ReportIcon: FactCheckOutlinedIcon,
  Brightness1OutlinedIcon,
  BookmarkAddOutlinedIcon,
  Company: BusinessOutlinedIcon,
  Info: InfoOutlinedIcon,
};
